import React from  "react";
import { ReactComponent as LikeIcon } from "../../assets/icons/thumb-up-outline.svg";

import './LikeButton.scss'

const LikeButton = ({ pressed = false, onClick = ()=>{}, quantity=0, disabled=false }) => {

    let classes = pressed ? "likes liked" : "likes";

    if(quantity >= 1000) {
        quantity = ""+(quantity/1000).toFixed(0) +"K";
    }

    if(quantity >= 1000000) {
        quantity = ""+(quantity/1000000).toFixed(0) +"M";
    }

    return (
        <button className={classes} onClick={onClick} disabled={disabled}>
            <LikeIcon/>
            <span className="quantity">{quantity}</span>
        </button>
    );

};

export default LikeButton;
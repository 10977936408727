import React from  "react";

import './PopUpInitial.scss';
// import testImage from '../../assets/img/pop-up-test.gif';

const PopUpInitial = ({image, onClose=()=>{}}) => {

    return (
        <div className="pop-up-initial">
            <div className="pop-up-initial-header">
                <button onClick={onClose}>X</button>
            </div>
            <div className="pop-up-initial-content">
                <img src={image} onClick={onClose} alt="" />
            </div>
        </div>
    );

};

export default PopUpInitial;
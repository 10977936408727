import React from  "react";

import "./ProfileImage.scss";

const ProfileImage = ({headColor='#5da3ff', faceColor='#000000', bodyColor='#5da3ff'}) => {

    return (
        <svg height="100%" strokeMiterlimit="10" style={{fillRule: 'nonzero', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round'}} version="1.1" viewBox="0 0 1024 1024" width="100%" xmlns="http://www.w3.org/2000/svg">
            <defs/>
            <g id="Capa-1">
                <path d="M233.244 62.0375L790.123 62.0375L790.123 528.855L233.244 528.855L233.244 62.0375ZM502.271 628.38L453.133 528.816L343.257 512.85L422.764 435.35L403.995 325.918L502.271 377.585L600.547 325.918L581.778 435.35L661.285 512.85L551.409 528.816L502.271 628.38Z" fill={headColor} fillRule="nonzero" opacity="1" stroke={headColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
            </g>
            <g id="Capa-2">
                <path d="M902.082 955.216C795.821 854.859 634.959 721.591 493.568 726.165C348.701 730.851 200.761 861.943 105.126 962.3C486.581 961.349 520.628 958.528 902.082 957.577" fill={bodyColor} fillRule="nonzero" opacity="1" stroke={bodyColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M613.997 871.979C610.455 870.207 599.829 864.894 603.371 866.665C606.913 868.436 617.539 873.75 613.997 871.979Z" fill={bodyColor} fillRule="nonzero" opacity="1" stroke={bodyColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
            </g>
            <g id="Capa-3">
                <path d="M415.421 236.142C415.421 224.946 422.997 215.869 432.342 215.869C441.687 215.869 449.263 224.946 449.263 236.142C449.263 247.338 441.687 256.414 432.342 256.414C422.997 256.414 415.421 247.338 415.421 236.142Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.421 236.142C415.421 224.946 422.997 215.869 432.342 215.869C441.687 215.869 449.263 224.946 449.263 236.142C449.263 247.338 441.687 256.414 432.342 256.414C422.997 256.414 415.421 247.338 415.421 236.142Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.421 236.142C415.421 224.946 422.997 215.869 432.342 215.869C441.687 215.869 449.263 224.946 449.263 236.142C449.263 247.338 441.687 256.414 432.342 256.414C422.997 256.414 415.421 247.338 415.421 236.142Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.421 236.142C415.421 224.946 422.997 215.869 432.342 215.869C441.687 215.869 449.263 224.946 449.263 236.142C449.263 247.338 441.687 256.414 432.342 256.414C422.997 256.414 415.421 247.338 415.421 236.142Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.421 236.142C415.421 224.946 422.997 215.869 432.342 215.869C441.687 215.869 449.263 224.946 449.263 236.142C449.263 247.338 441.687 256.414 432.342 256.414C422.997 256.414 415.421 247.338 415.421 236.142Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.421 236.142C415.421 224.946 422.997 215.869 432.342 215.869C441.687 215.869 449.263 224.946 449.263 236.142C449.263 247.338 441.687 256.414 432.342 256.414C422.997 256.414 415.421 247.338 415.421 236.142Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.421 236.142C415.421 224.946 422.997 215.869 432.342 215.869C441.687 215.869 449.263 224.946 449.263 236.142C449.263 247.338 441.687 256.414 432.342 256.414C422.997 256.414 415.421 247.338 415.421 236.142Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.935 359.987C415.935 348.791 423.511 339.714 432.856 339.714C442.201 339.714 449.777 348.791 449.777 359.987C449.777 371.183 442.201 380.259 432.856 380.259C423.511 380.259 415.935 371.183 415.935 359.987Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.935 359.987C415.935 348.791 423.511 339.714 432.856 339.714C442.201 339.714 449.777 348.791 449.777 359.987C449.777 371.183 442.201 380.259 432.856 380.259C423.511 380.259 415.935 371.183 415.935 359.987Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.935 359.987C415.935 348.791 423.511 339.714 432.856 339.714C442.201 339.714 449.777 348.791 449.777 359.987C449.777 371.183 442.201 380.259 432.856 380.259C423.511 380.259 415.935 371.183 415.935 359.987Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.935 359.987C415.935 348.791 423.511 339.714 432.856 339.714C442.201 339.714 449.777 348.791 449.777 359.987C449.777 371.183 442.201 380.259 432.856 380.259C423.511 380.259 415.935 371.183 415.935 359.987Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M415.935 359.987C415.935 348.791 423.511 339.714 432.856 339.714C442.201 339.714 449.777 348.791 449.777 359.987C449.777 371.183 442.201 380.259 432.856 380.259C423.511 380.259 415.935 371.183 415.935 359.987Z" fill={faceColor} fillRule="nonzero" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M571.942 204.256L596.877 254.92L621.813 305.584L598.677 358.928L575.541 412.272" fill="none" opacity="1" stroke={faceColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
            </g>
        </svg>
    );

};

export default ProfileImage;
import React from "react";
import ReactDOM  from "react-dom";
import './PopUpContainer.scss';

const PopUpContainer = (props) => {

    return ReactDOM.createPortal(
        <div className="pop-up-container">
            {props.children}
        </div>
    ,document.body);

};

export default PopUpContainer;
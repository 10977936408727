import React, { useState, useEffect, useRef } from "react";

import ProfileImage from "../profileImg/ProfileImage";
import LikeButton from "../buttons/LikeButton";
import DislikeButton from "../buttons/DislikeButton";

import { ReactComponent as SmileIcon } from '../../assets/icons/Smile.svg';
import { ReactComponent as SadIcon } from '../../assets/icons/Sad.svg';
import { ReactComponent as VerySadIcon } from '../../assets/icons/Very sad.svg';
import { ReactComponent as NeutralIcon } from '../../assets/icons/Neutral.svg';

import './Tought.scss';

const Tought = ({uuid='', text='', username='', feelingId = 0, likes = 0, dislikes = 0, hasImage = false, image=null, isDemo=false, timestamp="", profilePicAttrs={}, }) => {

    let reactionIcon = null;
    //const [pressedCounter, setPressedCounter] = useState(0);
    const [likeButtonPressed, setLikeButtonPressed] = useState(false); 
    const [dislikeButtonPressed, setDislikeButtonPressed] = useState(false); 
    const [buttonLikes, setLikes] = useState(+likes);
    const [internalLikes, setInternalLikes] = useState(+likes);
    const [buttonDislikes, setDislikes] = useState(+dislikes);
    const [internalDislikes, setInternalDislikes] = useState(+dislikes);
    const [useOverflowViewer, setUseOverflowViewer] = useState(false);
    const [viewerExpanded, setViewerExpanded] = useState(false);
    const firstUpdate = useRef(true);

    const onImageLoad = ({target: img}) => {
        // console.log(img.offsetHeight);
        if(+img.offsetHeight > 240) {
            setUseOverflowViewer(true);
        }
    };

    const expandView = () => {
        setViewerExpanded(true);
    };

    const registerLike = async (remove) => {  

        try {
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/toughts/likes${remove ? '?remove=1' : ''}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({uuid: uuid})
            });
            
            if(!response.ok){
                throw new Error('not found')
            }
            //let data = await response.json();
        } catch (e) {
            // console.log('not found');
        }
    };

    const registerDislike = async (remove) => {
        
        try {
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/toughts/dislikes${remove ? '?remove=1' : ''}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({uuid: uuid})
            });
    
            if(!response.ok){
                throw new Error('not found')
            }
            //let data = await response.json();
        } catch (e) {
            // console.log('not found');
        }
    };

    useEffect(() => {

        const timer = setTimeout(() => {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                //console.log('first render! avoiding request');
            } else {
                if((buttonLikes !== internalLikes)){
                    let remove = false;
                    if(buttonLikes < internalLikes) {
                        remove = true;
                    }
                    registerLike(remove);
                    setInternalLikes(buttonLikes);
                } else {
                    //console.log('Values stayed the same! canceling request...');
                }
            }
        }, 3000);

        return () => clearTimeout(timer);
    // eslint-disable-next-line
    }, [buttonLikes]);

    useEffect(() => {

        const timer = setTimeout(() => {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                //console.log('first render! avoiding request');
            } else {
                if((buttonDislikes !== internalDislikes)){
                    let remove = false;
                    if(buttonDislikes < internalDislikes) {
                        remove = true;
                    }
                    setInternalDislikes(buttonDislikes);
                    registerDislike(remove);
                } else {
                    //console.log('Values stayed the same! canceling request...');
                }
            }
        }, 3000);

        return () => clearTimeout(timer);
    // eslint-disable-next-line
    }, [buttonDislikes]);

    const likeButtonHandler = () => {
        
        setLikes(prevLikes => { return likeButtonPressed ? prevLikes - 1 : prevLikes + 1});           
        setLikeButtonPressed(prevPressed => !prevPressed);
        if(dislikeButtonPressed) {
            setDislikes(prevDislikes => +prevDislikes -1);
            setDislikeButtonPressed(false);
        }
    //    setPressedCounter(prev => prev + 1);
    };
    
    const dislikeButtonHandler = () => {
        
        setDislikes(prevDislikes => { return dislikeButtonPressed ? prevDislikes - 1 : prevDislikes + 1});           
        setDislikeButtonPressed(prevPressed => !prevPressed);
        if(likeButtonPressed) {
            setLikes(prevLikes => +prevLikes -1);
            setLikeButtonPressed(false);
        }

    //    setPressedCounter(prev => prev + 1);
    };

    switch(+feelingId) {
        case 1:
        default:
            reactionIcon = <SmileIcon/>    
        break;

        case 2: 
            reactionIcon = <SadIcon/>    
        break;

        case 3: 
            reactionIcon = <VerySadIcon/>    
        break;

        case 4:
            reactionIcon = <NeutralIcon/>    
        break;
    }

    return (
        <section className='tought_container'>
            <div className="tought_header">
                <div className="tought_picture_container">
                    <ProfileImage
                        headColor={profilePicAttrs.headColor}
                        faceColor={profilePicAttrs.faceColor}
                        bodyColor={profilePicAttrs.bodyColor}
                    />
                </div>
                <div className="tought_title">
                    <div className="tought_username">{username}</div>
                    <div className="tought_timestamp">{timestamp}</div>
                </div>
            </div>
            <div className="tought_content">
                <div className="tought_reaction">
                    {reactionIcon}
                </div>
                <p className="tought_text">
                    {text}
                </p>
            </div>
            {hasImage && 
                <div className={"content_picture_container "+(viewerExpanded && " expanded")}>
                    <img onLoad={onImageLoad} src={image} alt="" className="content_picture"/>
                    {useOverflowViewer && !viewerExpanded && <div className="content_picture_expander" onClick={expandView}></div> }
                </div>
            }
            <div className="tought_footer">
                <div className="button-group">
                    <LikeButton 
                        className="likes" 
                        onClick={likeButtonHandler}
                        quantity={buttonLikes}
                        pressed={likeButtonPressed}
                        disabled={isDemo}
                    />
                    <DislikeButton 
                        className="dislikes" 
                        onClick={dislikeButtonHandler}
                        quantity={buttonDislikes}
                        pressed={dislikeButtonPressed}
                        disabled={isDemo}
                    />
                </div>
            </div>
        </section>
    );
};

export default Tought;
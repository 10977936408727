import React, { useState, useEffect } from  "react";
import { Helmet } from "react-helmet";

import Header, { THEMES } from '../../components/header/Header';
import ProfileImage from '../../components/profileImg/ProfileImage';
import publicIcon from '../../assets/icons/public1.png';
import loadingSpinner from '../../assets/icons/spinner1.gif';
import Tought from "../../components/tought/Tought";

import { ReactComponent as SmileIcon } from '../../assets/icons/Smile.svg';
import { ReactComponent as AngryIcon } from '../../assets/icons/Sad.svg';
import { ReactComponent as SadIcon } from '../../assets/icons/Very sad.svg';
import { ReactComponent as NeutralIcon } from '../../assets/icons/Neutral.svg';

import './NewTought.scss';

const colors = [
    '#c45161',
    '#e094a0',
    '#f2b6c0',
    '#f2dde1',
    '#cbc7d8',
    '#8db7d2',
    '#5e62a9',
    '#434279',
];

const colorIndex = Math.floor(Math.random() * 7);
const selectedColor = colors[colorIndex];
const faceColor = colorIndex < 5 ? '#000000': '#ffffff';

const CharacterCounter = ({current=0, total=0}) => {

    let color = "#000";

    if(total-current < 15){
        color = "#e07014";
    }

    if(total <= current) {
        color = "#d40808"
    }

    return (
        <span style={{color: color}}>{current}/{total}</span>
    );
};

const NewTought = (props) => {

    const [loading, setLoading] = useState(false);
    const [demoTought, setDemoTought] = useState(null);
    const [error, setError] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const [selectedFeeling, setSelectedFeeling] = useState(null);
    const [toughtSubmitted, setToughtSubmitted] = useState(false);
    const [shouldReset, setShouldReset] = useState(false);
    useEffect(() => {
        const fetchToughts = async () => {
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/toughts/random?q=1&excludeImgPosts=1`);
            let data = await response.json();
            setDemoTought(data.message.data[0]);
        };
        setLoading(true);
        try {
            fetchToughts();
        } catch (e) {
            setLoading(false);
        }
        setLoading(false);
    }, [shouldReset]);

    const resetPageHandler = () => {
        setLoading(true);
        setDemoTought(null);
        setError(false);
        setCurrentText('');
        setSelectedFeeling(null);
        setToughtSubmitted(false);
        setShouldReset(prev => !prev);
    };
    
    const setTextHandler = (text) => {
        let lineBreaks =  text.split(/\r\n|\r|\n/).length;
        if(text.length <= 120 & lineBreaks < 8) {
            setCurrentText(text);
        }
    }; 

    const submitHandler = async () => {
        setError(false);
        setLoading(true);
        try {
            let response = await fetch(process.env.REACT_APP_BACKEND_URL+`/toughts`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({text: currentText, feeling_id: selectedFeeling})
            });
            if(!response.ok){
                throw new Error("Request Error!");
            }
        } catch (e) {
            setLoading(false);
            setError(true);
        }
        setToughtSubmitted(true);
        setLoading(false);
    };

    let activeButton = (currentText.length > 0 && selectedFeeling !== null);

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="¡Publica lo que piensas!"
                />
                <title>Nuevo Pensamiento</title>
                <link rel="icon" href="favicon2.svg" />
            </Helmet>
            <Header theme={ THEMES.input }/>
            {!toughtSubmitted && 
            <main className="container">
                <div className="tought-input">
                    <div className="tought-input_header">
                        <div className="tought-input_picture_container">
                            <ProfileImage
                                headColor={selectedColor}
                                faceColor={faceColor}
                                bodyColor={selectedColor}
                            />
                        </div>
                        <div className="tought-input_availability">
                            <img src={publicIcon} alt="" className="availability_icon" />
                            <div className="availability_tagname">Público</div>
                        </div>
                        <div className="tought-input_character-counter">
                            <CharacterCounter current={currentText.length} total={120}/>
                        </div>
                    </div>
                    <div className="tought-input_input">
                        <textarea name="text" cols="30" rows="7" onChange={(e) => setTextHandler(e.target.value)} value={currentText}></textarea>
                    </div>
                    <div className="tought-input_footer">
                        <div className={`icon_box ${selectedFeeling === 1 ? "active_icon" : ""}`} onClick={() => setSelectedFeeling(1)}>
                            <SmileIcon/>
                        </div>
                        <div className={`icon_box ${selectedFeeling === 2 ? "active_icon" : ""}`} onClick={() => setSelectedFeeling(2)}>
                            <SadIcon/>
                        </div>
                        <div className={`icon_box ${selectedFeeling === 3 ? "active_icon" : ""}`} onClick={() => setSelectedFeeling(3)}>
                            <AngryIcon/>
                        </div>
                        <div className={`icon_box ${selectedFeeling === 4 ? "active_icon" : ""}`} onClick={() => setSelectedFeeling(4)}>
                            <NeutralIcon/>
                        </div>
                    </div>
                </div>
                {loading &&
                    <div className="loading-spinner">
                        <img src={loadingSpinner} alt="" />
                    </div>
                }
                {!loading &&
                    <button className="submit-btn" disabled={!activeButton} onClick={submitHandler}>Publicar</button>
                }
                {!loading && error && 
                    <div className="error-message">
                        Ha ocurrido un error, por favor intenta de nuevo
                    </div>
                }
                {demoTought && 
                <div className="demo-tought">
                    <h1>Actividad Reciente</h1>
                    <Tought
                        uuid={demoTought.uuid}
                        username={"Usuario #"+demoTought.username}
                        timestamp={"Hace "+demoTought.date_created}
                        text={demoTought.text}
                        feelingId={demoTought.feeling_id}
                        likes={demoTought.likes}
                        dislikes={demoTought.dislikes}
                        hasImage={false}
                        image={null}
                        profilePicAttrs={JSON.parse(demoTought.profile_pic_attrs)}
                        isDemo={true}
                    />
                </div>}
            </main>
            }
            {toughtSubmitted && 
                <div className="container">
                    <div className="tought-submitted-message">
                        <span>¿Publicar otra historia?</span> 
                        <div className="tought-submitted-container">
                            <button className="tought-submitted-btn" onClick={resetPageHandler}>
                                Sí
                            </button>
                            <button className="tought-submitted-btn" onClick={() => {window.location = 'https://www.google.com'}}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    );

};

export default NewTought;
import React, { useState, useEffect } from  "react";

import './PopUpSpammer.scss';

const PopUps = [
    {id: 0, top: "0%", left: "40%"},
    {id: 1, top: "45%", left: "45%"},
    {id: 2, top: "50%", left: "-5%"},
    {id: 3, top: "80%", left: "10%"},
    {id: 4, top: "35%", left: "25%"},
    {id: 5, top: "5%", left: "10%"},
    {id: 6, top: "20%", left: "-20%"},
    {id: 7, top: "10%", left: "45%"},
    {id: 8, top: "25%", left: "50%"},
    {id: 9, top: "70%", left: "55%"},
    {id: 10, top: "90%", left: "10%"},
    {id: 11, top: "85%", left: "5%"},
    {id: 12, top: "65%", left: "15%"},
    {id: 13, top: "57%", left: "90%"},
    {id: 14, top: "100%", left: "70%"},

];

const SpamPopUp = ({image, top="", left="", zIndex=0}) => {

    return (
        <div className="pop-up-spammer" style={{top: top, left: left, zIndex: zIndex}}>
            <div className="pop-up-spammer-content">
                <img src={image} alt="" />
            </div>
        </div>
    );
};

const PopUpSpammer = ({images}) => {

    const SPAM_LIMIT = 15;
    const [spamIndex, setSpamIndex] = useState(0);
    const [allPopUpsShown, setAllPopUpsShown] = useState(false);
    const [currentPointer, setCurrentPointer] = useState(0);
    // eslint-disable-next-line
    const [zIndexPoint, setZIndexPoint] = useState(-1);
    const [zIndexes, setZIndexes] = useState(new Array(15).fill(0, 0, 14));

    let activePopUps = PopUps.filter(item => item.id <= spamIndex);

    useEffect(() => {
        
        let spamTimeout = null;
        spamTimeout = setTimeout(() => {
            setSpamIndex(prev => prev + 1);
            if(currentPointer > SPAM_LIMIT) {
                setCurrentPointer(0);
            } else {
                setCurrentPointer(prev => prev + 1);
                // console.log(currentPointer);
            }
            let newIndexes = zIndexes;
            newIndexes[currentPointer] = spamIndex;
            setZIndexes(newIndexes);
            // console.log(zIndexes);
            if(spamIndex > SPAM_LIMIT) {
                if(!allPopUpsShown) {
                    setAllPopUpsShown(true);
                }
                setZIndexPoint(prev => prev +1);
            }
        }, 100);

        return () => clearTimeout(spamTimeout);
    });
   
    return (
        <>
        {activePopUps.map(item => {

            return (<SpamPopUp
                id={item.id}
                key={item.id}
                top={item.top}
                left={item.left}
                zIndex={zIndexes[item.id]}
                image={images[item.id]}
            />);
            })
        }
        </>
    );

};

export default PopUpSpammer;
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header, { THEMES }  from '../../components/header/Header';
import Tought from '../../components/tought/Tought';
import PopUpContainer from '../../components/popUps/PopUpContainer';
import PopUpInitial from '../../components/popUps/PopUpInitial';
import PopUpSpammer from '../../components/popUps/PopUpSpammer';
import ExitButton from '../../components/popUps/ExitButton';

import './Home.scss';

//Image Files
import image1 from  '../../assets/img/stock/1.webp';
import image2 from  '../../assets/img/stock/2.webp';
import image3 from  '../../assets/img/stock/3.webp';
import image4 from  '../../assets/img/stock/4.webp';
import image5 from  '../../assets/img/stock/5.webp';
import image6 from  '../../assets/img/stock/12.webp';
import image7 from  '../../assets/img/stock/7.webp';
import image8 from  '../../assets/img/stock/8.webp';
import image9 from  '../../assets/img/stock/14.webp';
import image10 from '../../assets/img/stock/10.webp';
import image11 from '../../assets/img/stock/11.webp';
import image12 from '../../assets/img/stock/6.webp';
import image13 from '../../assets/img/stock/13.webp';
import image14 from '../../assets/img/stock/9.webp';
import image15 from '../../assets/img/stock/15.webp';

import toughtImage1 from  '../../assets/img/toughts/1.webp';
import toughtImage2 from  '../../assets/img/toughts/2.webp';
import toughtImage3 from  '../../assets/img/toughts/3.webp';
import toughtImage4 from  '../../assets/img/toughts/4.webp';
import toughtImage5 from  '../../assets/img/toughts/5.webp';
import toughtImage6 from  '../../assets/img/toughts/6.webp';
import toughtImage7 from  '../../assets/img/toughts/7.webp';

const toughtImages = [
    toughtImage1,
    toughtImage2,
    toughtImage3,
    toughtImage4,
    toughtImage5,
    toughtImage6,
    toughtImage7,
];

const stockImages = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
];

const Home = (props) => {

    const [loading, setLoading] = useState(true);
    const [toughts, setToughts] = useState([]);
    const [scrolledToMiddle, setScrolledToMiddle] = useState(false);
    const [displayFirstPopUp, setDisplayFirstPopUp] = useState(false);
    const [firstPopUpClosed, setFirstPopUpClosed] = useState(false);
    const [spamTriggered, setSpamTriggered] = useState(false);
    const [showExit, setShowExit] = useState(false);

    useEffect(() => {
        const fetchToughts = async () => {
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/toughts/random?q=35`);
            let data = await response.json();
            setToughts(data.message.data);
        };
        try {
            fetchToughts();
        } catch (e) {
            setLoading(false);
        }
        setLoading(false);
    }, []);

    //Scroll Trigger for first pop up
    useEffect(() => {
        if(!scrolledToMiddle) {
            const onScroll = (e) => {
                let element = e.target.documentElement;
                if ((element.scrollTop) > (element.scrollHeight - element.scrollTop)) {
                    setScrolledToMiddle(true);      
                    setDisplayFirstPopUp(true);
                }};
            
            window.addEventListener('scroll', onScroll);
        
            return () => window.removeEventListener('scroll', onScroll);
        }
    }, [scrolledToMiddle]);
    
    //Pop up spam trigger
    useEffect(() => {
        
        let spamTimeout = null;

        if(firstPopUpClosed) {
            spamTimeout = setTimeout(() => {
                setSpamTriggered(true);
            }, 4000);
        }

        return clearTimeout(spamTimeout);

    }, [firstPopUpClosed]);

    //Show exit trigger
    useEffect(() => {
        
        let exitTimeout = null;

        if(spamTriggered) {
            exitTimeout = setTimeout(() => {
                setShowExit(true);
            }, 4000);
        }

        return clearTimeout(exitTimeout);

    }, [spamTriggered]);


    const closeFirstPopUpHandler = () => {

        setDisplayFirstPopUp(false);
        setFirstPopUpClosed(true);
        setTimeout(() => {
            setSpamTriggered(true);
        }, 4000);
    }

    if(spamTriggered && !showExit) {
        setTimeout(() => {
            setShowExit(true);
        }, 8000);
    }

    let popUpInitialIndex = Math.floor(Math.random() * 15);

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="La Red Social de Pensamientos"
                />
                <title>Facetter</title>
                <link rel="icon" href="favicon.svg" />
            </Helmet>
            {displayFirstPopUp && 
                <PopUpContainer>
                    <PopUpInitial image={stockImages[popUpInitialIndex]} onClose={closeFirstPopUpHandler}/>
                </PopUpContainer>
            }
            {spamTriggered && 
                <PopUpContainer>
                    <PopUpSpammer images={stockImages}/>
                </PopUpContainer>
            }
            {showExit && 
                <PopUpContainer>
                    <ExitButton/>
                </PopUpContainer>
            }
            <Header displayNewIcon={true} theme={THEMES.normal}/>
            <main className='container'>
                {loading && <h1>Loading...</h1>}
                {!loading && (
                    toughts.map(item => (
                        <Tought
                            key={item.uuid}
                            uuid={item.uuid}
                            username={"Usuario #"+item.username}
                            timestamp={"Hace "+item.date_created}
                            text={item.text}
                            feelingId={item.feeling_id}
                            likes={item.likes}
                            dislikes={item.dislikes}
                            hasImage={item.image !== null}
                            image={item.image !== null ? toughtImages[(+item.image) - 1] : null}
                            profilePicAttrs={JSON.parse(item.profile_pic_attrs)}
                        />
                    ))
                )}
            </main>
        </>
    );
};

export default Home;
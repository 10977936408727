import React, { useEffect, useState } from  "react";
import { Helmet } from 'react-helmet';

import newsImage1 from '../../assets/img/news/1.webp';
import newsImage2 from '../../assets/img/news/2.webp';
import newsImage3 from '../../assets/img/news/3.webp';
import newsImage4 from '../../assets/img/news/4.webp';
import newsImage5 from '../../assets/img/news/5.webp';
import newsImage6 from '../../assets/img/news/6.webp';

import './Texts.scss';

import Header, { THEMES } from "../../components/header/Header";

const ITERATION_LENGHTS = [
    //5000,
    4000,
    // 3000,
    2000,
    1000,
    500,
    200,
    100,
];

const NEWS_QUANTITY = 6;

const Texts = (props) => {

    // eslint-disable-next-line
    const [shouldIterate, setShouldIterate] = useState(true);
    const [textIndex, setTextIndex] = useState(1);
    const [indexResetCounter, setIndexResetCounter] = useState(0);
    const [iterationCounter, setIterationCounter] = useState(1);

    useEffect(()=>{
        if(shouldIterate) {
            const timer = setInterval(() => {
                if(textIndex === NEWS_QUANTITY) {
                    setTextIndex(1);
                    if(indexResetCounter < ITERATION_LENGHTS.length - 1) {
                        setIndexResetCounter(prev => prev + 1);
                    }
                } else {
                    setTextIndex(prev => prev + 1);
                }
                setIterationCounter(prev => prev +1);  
    
                // console.log("text index: "+textIndex+", iteration: "+iterationCounter, ",interval length: "+ITERATION_LENGHTS[indexResetCounter]);
    
            }, ITERATION_LENGHTS[indexResetCounter]);
    
            return () => clearInterval(timer);
        }
    }, [textIndex, iterationCounter, indexResetCounter, shouldIterate]);

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Timeline - Noticias"
                />
                <title>Facetter Noticias</title>
                <link rel="icon" href="favicon3.svg" />
            </Helmet>
            <Header theme={THEMES.news}/>
            <div className="container">
                {textIndex === 1 && 
                
                <div className="news_text">
                    <h3 className="news_title"><b>Lo último sobre la guerra en Ucrania</b></h3>
                    <div className="news_image_container">
                        <img className="news_image" src={newsImage1} alt="ukraine"/>
                    </div>
                    <br/>
                    <p>
                    El 24 de febrero Rusia lanzó una invasión a Ucrania por tierra, aire y mar tras meses de tensiones entre Moscú y Kyiv. El ataque desencadenó una cadena de eventos durante los meses siguientes, incluidas sanciones sin precedentes contra Rusia y la expansión de la OTAN. Decenas de miles de personas han perdido la vida y más de 7,5 millones se han visto obligadas a huir de sus hogares, la mayor oleada de refugiados en los países de la OCDE desde la Segunda Guerra Mundial.</p>
                </div>}
                {textIndex === 2 &&

                <div className="news_text">
                    <h3 className="news_title"><b>México celebra el Día de Muertos</b></h3>
                    <div className="news_image_container">
                        <img className="news_image" src={newsImage2} alt="amlo"/>
                    </div>
                    <br/>
                    <p>Entre el 1 y el 2 de noviembre, alrededor del país se hacen presentes las celebraciones por el #DíaDeMuertos con ofrendas, decoraciones, desfiles y otras actividades en honor a los seres queridos difuntos. 🌼💀🏵️</p>
                </div>}
                {textIndex === 3 &&
                    
                <div className="news_text">
                    <h3 className="news_title"><b>AMLO califica de rateros, clasistas e hipócritas a los organizadores de la marcha en defensa del INE: “Que lleguen al Zócalo, yo ni voy a estar aquí"</b></h3>
                    <div className="news_image_container">
                        <img className="news_image" src={newsImage3} alt="dos_de_noviembre"/>
                    </div>
                    <br/>
                    <p>
                        El presidente de México, Andrés Manuel López Obrador, criticó este lunes a los organizadores de la marcha para defender al Instituto Nacional Electoral (INE) ante su reforma electoral, a quienes calificó de hipócritas, clasistas y rateros, y pidió a sus simpatizantes no asistir a la manifestación para no caer en provocaciones.
                        “Cómo no va a convocar Fox a la marcha en contra de nosotros si está molesto, porque ya no se le dan los 5 millones de pesos de pensión y ya no tiene toda la protección que tenía del Estado Mayor y ya no puede recomendar a nadie y lo mismo el caso de Calderón y todos”.
                    </p> 
                    <br/>
                    <p>
                        “Pero ya la gente sabe de qué se trata, sólo que no mientan, porque son muy hipócritas, hablan de que se va a destruir al INE, que va a haber una dictadura, que lo estoy haciendo porque me voy a quedar, que va a haber reelección, que no se dejen engañar“.
                    </p>
                    <br/>
                    <p>
                        “La gente que vaya que sepa que es una marcha, una manifestación en contra de nosotros por la política que estamos llevando a cabo en favor del pueblo. Todos esos aunque vayan a misa los domingos no le tienen amor al pueblo, y son racistas en su mayoría, clasistas, muy hipócritas, o sea que lo sepan, que se sepa bien”, señaló en su conferencia matutina.
                        El mandatario federal dijo que el 13 noviembre —día en que está convocada la marcha— no estará en la Ciudad de México, pues pasará su cumpleaños en Palenque, Chiapas, y remarcó que todos tienen derecho a protestar en el Zócalo.
                    </p> 
                    <br/>
                    <p>
                        “Tienen todo el derecho a manifestarse, además ya fuera máscaras que participen todos en la marcha y procurar no caer en ninguna provocación, que puedan llegar al Zócalo sin ningún problema. Hago un llamado a todos los ciudadanos, a los que simpatizan y participan en el movimiento de transformación para que se queden en sus casas, que no haya ninguna reunión, que nadie vaya a provocar”.
                        El Frente Cívico Nacional y la organización Unid@s han señalado que la marcha fue convocada en defensa de la democracia y el INE, ante el debate de la reforma electoral propuesta por el Ejecutivo federal.
                        El líder del PAN, Marko Cortés, anunció que su partido también se unirá a la manifestación, luego de que concluya su Asamblea Nacional.
                    </p>
                    
                </div>}
                {textIndex === 4 && 
                <div className="news_text">
                    <h3 className="news_title"><b>Protestas en Nueva Zelanda en contra de la vacunación obligatoria</b></h3>
                    <div className="news_image_container">
                        <img className="news_image" src={newsImage4} alt="dos_de_noviembre"/>
                    </div>
                    <br/>
                    <p>
                        Un convoy antivacunas ha salido a la calle este martes frente al Parlamento de Nueva Zelanda en Wellington. Cientos de personas han protestado contra la vacunación obligatoria para determinadas profesiones, como profesores, médicos, enfermeros, policías o militares.
                    </p>
                    <br/>
                    <p>    
                        Con mensajes como "la coacción no es consentimiento", los manifestantes exigen el fin de las restricciones argumentando la falta de libertad. En Nueva Zelanda es necesario el certificado de vacunación para entrar en restaurantes, participar en eventos deportivos o servicios religiosos.
                    </p> 
                    <br/>   
                    <p>    
                        "Se trata de la libertad de elección y de que se nos permita gobernar nuestro propio cuerpo en lugar de que nos lo digan", comenta una de las manifestantes. 
                    </p>
                    <br/>
                    <p>
                        "Estoy a favor de las vacunas. La vacuna es algo realmente bueno. Pero deberíamos poder decidir si queremos tenerla o no", declara un ciudadano neozelandés. 
                    </p> 
                    <br/>   
                    <p>
                        La manifestación coincide con la reapertura del Parlamento tras las vacaciones de verano. La primera ministra, Jacinda Ardern, afirmó que no tenía intención de enfrentarse a los participantes, y reafirmo que la mayoría de los neozelandeses habían mostrado su apoyo al programa de vacunación del gobierno.
                    </p>
                    <br/>
                    <p>    
                        Nueva Zelanda ha alcanzado el 77% de la población vacunada, pero la cifra de contagios de la variante ómicron está en auge, alcanzando los <b>200 contagios diarios.</b>
                    </p> 
                    <br/>
                    <p>
                        En la manifestación, varios manifestantes llevaban banderas canadienses como muestra de apoyo a los cientos de camiones que asedian la capital canadiense desde hace más de 10 días. El alcalde de Ottawa pidió el lunes a las autoridades federales que ayuden a poner fin al asedio.
                    </p>
                </div>
                }
                {textIndex === 5 && 
                <div className="news_text">
                    <h3 className="news_title"><b>DECOMISA FESC ARMAS DE FUEGO Y DROGAS A TRES HOMBRES EN MEXICALI</b></h3>
                    <div className="news_image_container">
                        <img className="news_image" src={newsImage5} alt="dos_de_noviembre"/>
                    </div>
                    <br/>
                    <p>
                        Las armas de diferentes calibres estaban abastecidas con tiros útiles
                        tras cometer una falta administrativa tres hombres fueron sorprendidos por agentes de la Fuerza Estatal de Seguridad Ciudadana (FESC) en poder de armas de fuego y sustancias prohibidas, en las inmediaciones de la colonia Baja California, en el municipio de Mexicali. Elementos de la Secretaría de la Defensa Nacional (SEDENA) apoyaron en el resguardo perimetral de la escena.
                    </p>
                    <br/>
                    <p>
                        Jorge Luis “N”, de 32 años, Miguel Ángel “N”, de 20 años y Josué “N”, de 41 años, viajaban a bordo de un vehículo Toyota Matrix sin placas visibles, cuando se percataron de la presencia policial y en ese momento el conductor aceleró la marcha del motor, sin respetar la señal gráfica de alto.
                    </p>
                    <br/>
                    <p>
                        A través del altoparlante se les pidió que se detuvieran, haciendo caso omiso a los comandos verbales, por lo que activó el protocolo de vehículo en fuga, siendo interceptados en la avenida Querétaro esquina con Bahía de Todos los Santos, de la colonia Baja California.
                    </p>
                    <br/>
                    <p>
                        Dentro del automóvil se localizaron tres armas de fuego tipo pistola de diferentes calibres y abastecidas con tiros útiles, así como diversas cantidades de marihuana y heroína; siendo lo que a continuación se describe:
                    </p>
                    <br/>
                    <ul>
                        <li>
                            Arma de fuego tipo pistola calibre 9 milímetros con 01 cargador abastecido con 06 cartuchos útiles.
                        </li>
                        <li>
                            Arma de fuego tipo pistola calibre .25 milímetros con 01 cargador abastecido con 05 cartuchos útiles.
                        </li>
                        <li>
                            Arma de fuego tipo pistola calibre 380 milímetros con 01 cargador abastecido con 06 cartuchos útiles.
                        </li>
                        <li>
                            Bolsas de plástico de las cuales contienen una hierba verde y seca con las características propias de la droga conocida como marihuana, dando un peso aproximado de 3,100 gramos.
                        </li>
                        <li>
                            envoltorios de plástico trasparente cerradas con nudo que contienen una sustancia café con las características de la droga conocida como heroína, con un peso aproximado de 184 gramos.
                        </li>
                    </ul>
                    <br/>
                    <p>
                        Cabe señalar que elementos de SEDENA apoyaron en el traslado de los detenidos y los objetos asegurados hacia las instalaciones de la Fiscalía General de la República (FGR), autoridad encargada de dictaminar la situación jurídica de los individuos involucrados.
                    </p>
                    <br/>
                    <p> 
                        Con estas acciones la Secretaría de Seguridad Ciudadana de Baja California (SSCBC) refrenda el compromiso que tiene con la ciudadanía, en el sentido de mantener una permanente vigilancia de las calles y colonias de los siete municipios, asegurando todo artefacto que sea utilizado para robar la paz y la armonía social.
                    </p>
                </div>
                }
                {textIndex === 6 && 
                <div className="news_text">
                    <h3 className="news_title"><b>FUERON $20 MIL PESOS  EN CACHARPAS LO ROBADO EN LA  CASA DE CAMBIO, FAST CASH</b></h3>
                    <div className="news_image_container">
                        <img className="news_image" src={newsImage6} alt="dos_de_noviembre"/>
                    </div>
                    <br/>
                    <p>
                        Eran casi las diez de la mañana cuando se reportó que en la casa de cambio Fast Cash ubicada en la plaza Absa de la colonia Independencia, habían sufrido un robo y que los presuntos delincuentes utilizaron la técnica del “coscorrón”, es decir hicieron un boquete en el techo por donde se metieron a robar.
                    </p>
                    <br/>
                    <p>
                        Agentes de la Policía Municipal llegaron al bulevar Lázaro Cárdenas casi esquina con Río Presidio al centro cambiario, donde un empleado les dijo que al llegar a abrir el local en la mañana encontró todo revuelto y que el fondo de operación, que eran como 20 mil pesos de cacharpa no estaba.    
                    </p>
                    <br/>    
                    <p>   
                        Los agentes constataron que el techo del cuartito del comercio había sido violentado y se podía ver el cielo. Se presumió el ilícito ocurrió durante la madrugada, desconociendo la hora exacta. Se llamó a los de la FGE para que se iniciaran las investigaciones del caso.
                    </p>
                </div>
                }
            </div>
        </>
    );

};

export default Texts;
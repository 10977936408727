import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Home from "./pages/home/Home";
import NewTought from "./pages/newTought/NewTought";
import Texts from "./pages/texts/Texts";

function App() {
    
    return (
        <>
            <Routes>
                <Route path="/timeline/" element={<Home/>} />
                <Route path="/timeline/new" element={<NewTought/>} />
                <Route path="/timeline/texts" element={<Texts/>} />
                <Route path="*" element={<Navigate to="/timeline/"/>} />
            </Routes>
        </>
    );
}

export default App;

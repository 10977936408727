import React from 'react';
import { NavLink } from 'react-router-dom';

import LogoSVG from '../logo/Logo';

import newToughtImage from '../../assets/icons/pen.png';

import './Header.scss';

export const THEMES = {
    normal: "#4189ff",
    input: "#8466FB",
    news: "#00BA7B",
}
const Header = ({displayNewIcon = false, theme = THEMES.normal}) => {

    let component = process.env.NODE_ENV === "development" ? (
        <>
            <NavLink to="/timeline/">
                <LogoSVG color={theme} className="header_logo" alt="Main Logo"/>
                <span>Noticias</span>
            </NavLink>
            {displayNewIcon &&
                <>
                <NavLink to="/timeline/new">
                    <img src={newToughtImage} alt="" className="header_new-tought" />
                </NavLink>
                <NavLink to="/timeline/texts" style={{display: 'block'}}>
                    texts
                </NavLink>
                </>
            }
        </>
    ) : (
        <>
            <LogoSVG color={theme}/>
        </>
    );
    
    return (
        <header className="header">
            {component}
        </header>
    );
};

export default Header;
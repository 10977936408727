import React from  "react";

import { ReactComponent as ExitButtonSVG } from '../../assets/icons/Button.svg';

import './ExitButton.scss';

const ExitButton = (props) => {

    return (
        <div className="exit-button-container">
            <a className="exit-button-btn" href="https://www.google.com">
                <ExitButtonSVG/>
            </a>
        </div>
    );

};

export default ExitButton;
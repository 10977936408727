import React from  "react";

const LogoSVG = ({color = "#000000"}) => {

    return (
        <svg height="100%" strokeMiterlimit="10" style={{fillRule:"nonzero", clipRule:"evenodd",strokeLinecap:"round",strokeLinejoin:"round"}} version="1.1" viewBox="0 0 1024 1024" width="100%" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg"  >
            <defs/>
            <g id="Capa-1" opacity="0.5"/>
            <g id="Capa-2">
                <path d="M462.666 734.981L138.132 734.801L138.132 218.801L948.132 218.801L948.132 734.801L653.408 739.762" fill="none" opacity="1" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M461.256 735.931L369.132 923.801" fill="none" opacity="1" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M652.32 740.034L379.221 922.617" fill="none" opacity="1" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M376.728 366.362C376.728 339.302 398.993 317.366 426.459 317.366C453.924 317.366 476.19 339.302 476.19 366.362C476.19 393.422 453.924 415.358 426.459 415.358C398.993 415.358 376.728 393.422 376.728 366.362Z" fill="none" opacity="1" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M378.198 558.67C378.198 530.934 401.231 508.45 429.644 508.45C458.056 508.45 481.089 530.934 481.089 558.67C481.089 586.406 458.056 608.891 429.644 608.891C401.231 608.891 378.198 586.406 378.198 558.67Z" fill="none" opacity="1" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M614.093 306.507L618.644 424.433L625.362 598.521" fill="none" opacity="1" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
                <path d="M621.462 310.017C687.116 348.234 731.012 369.437 732.682 440.836C733.934 494.3 675.847 569.204 627.832 600.072" fill="none" opacity="1" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="48.9634"/>
            </g>
        </svg>
    );
};

export default LogoSVG;